import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _49b1320c = () => interopDefault(import('../pages/blog/Blog.vue' /* webpackChunkName: "" */))
const _46430e57 = () => interopDefault(import('../_templates/pages/checkout/Checkout.vue' /* webpackChunkName: "" */))
const _7d8817f6 = () => interopDefault(import('../_templates/pages/checkout/Checkout/Order.vue' /* webpackChunkName: "" */))
const _4d468f55 = () => interopDefault(import('../_templates/pages/checkout/Checkout/Account.vue' /* webpackChunkName: "" */))
const _22287446 = () => interopDefault(import('../_templates/pages/checkout/Checkout/ThankYou.vue' /* webpackChunkName: "" */))
const _409b5dd5 = () => interopDefault(import('../pages/customer/MyAccount/MyAccount.vue' /* webpackChunkName: "" */))
const _abfb79d0 = () => interopDefault(import('../_templates/pages/customer/MyAccount/AddressesDetails/AddressesDetails.vue' /* webpackChunkName: "" */))
const _4ee45994 = () => interopDefault(import('../_templates/pages/customer/MyAccount/MyProfile/MyProfile.vue' /* webpackChunkName: "" */))
const _099d5158 = () => interopDefault(import('../_templates/pages/customer/MyAccount/OrderHistory/OrderHistory.vue' /* webpackChunkName: "" */))
const _6440b9e4 = () => interopDefault(import('../_templates/pages/customer/MyAccount/AddressesDetails/AddressNew.vue' /* webpackChunkName: "" */))
const _33db9376 = () => interopDefault(import('../_templates/pages/customer/MyAccount/AddressesDetails/AddressEdit.vue' /* webpackChunkName: "" */))
const _71874aca = () => interopDefault(import('../_templates/pages/customer/MyAccount/OrderHistory/SingleOrder/SingleOrder.vue' /* webpackChunkName: "" */))
const _2fb8e076 = () => interopDefault(import('../_templates/pages/faq/Faq.vue' /* webpackChunkName: "" */))
const _6a1b6320 = () => interopDefault(import('../_templates/pages/payment-nets/Checkout.vue' /* webpackChunkName: "" */))
const _195ed51c = () => interopDefault(import('../pages/nettbutikk.vue' /* webpackChunkName: "pages/nettbutikk" */))
const _2abcbd37 = () => interopDefault(import('../_templates/pages/wishlist/Wishlist.vue' /* webpackChunkName: "" */))
const _65f29b97 = () => interopDefault(import('../_templates/pages/search-loop54/Search.vue' /* webpackChunkName: "" */))
const _218e04cc = () => interopDefault(import('../pages/blog/BlogPost.vue' /* webpackChunkName: "pages/blog/BlogPost" */))
const _26f75332 = () => interopDefault(import('../pages/catalog/Category.vue' /* webpackChunkName: "pages/catalog/Category" */))
const _b804425a = () => interopDefault(import('../_templates/pages/customer/MyAccount/ResetPassword.vue' /* webpackChunkName: "" */))
const _23f88440 = () => interopDefault(import('../pages/theme/Cms.vue' /* webpackChunkName: "pages/theme/Cms" */))
const _3c4f9958 = () => interopDefault(import('../pages/theme/Home.vue' /* webpackChunkName: "pages/theme/Home" */))
const _ed424286 = () => interopDefault(import('../_templates/pages/projects/ProjectDetail.vue' /* webpackChunkName: "" */))
const _75254702 = () => interopDefault(import('../_templates/pages/attribute-collection/AttributeCollection.vue' /* webpackChunkName: "" */))
const _9919c096 = () => interopDefault(import('../_templates/pages/attribute-collection/AttributeCollectionItem.vue' /* webpackChunkName: "" */))
const _7fea061d = () => interopDefault(import('../_templates/pages/theme/Page.vue' /* webpackChunkName: "" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/blogg-restaurantinnredning",
    component: _49b1320c,
    name: "blog___niche_nb_NO"
  }, {
    path: "/checkout",
    component: _46430e57,
    name: "checkout___niche_nb_NO",
    children: [{
      path: "bestilling",
      component: _7d8817f6,
      name: "checkout-order___niche_nb_NO"
    }, {
      path: "konto",
      component: _4d468f55,
      name: "checkout-account___niche_nb_NO"
    }, {
      path: "takk",
      component: _22287446,
      name: "checkout-thank-you___niche_nb_NO"
    }]
  }, {
    path: "/customer",
    component: _409b5dd5,
    meta: {"titleLabel":"Account"},
    name: "customer___niche_nb_NO",
    children: [{
      path: "addresses-details",
      component: _abfb79d0,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details___niche_nb_NO"
    }, {
      path: "my-profile",
      component: _4ee45994,
      meta: {"titleLabel":"My Account"},
      name: "customer-my-profile___niche_nb_NO"
    }, {
      path: "order-history",
      component: _099d5158,
      meta: {"titleLabel":"Order history"},
      name: "customer-order-history___niche_nb_NO"
    }, {
      path: "addresses-details/create",
      component: _6440b9e4,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details-new___niche_nb_NO"
    }, {
      path: "addresses-details/edit/:addressId",
      component: _33db9376,
      meta: {"titleLabel":"Addresses details"},
      props: true,
      name: "customer-addresses-details-edit___niche_nb_NO"
    }, {
      path: "order-history/:orderId",
      component: _71874aca,
      meta: {"titleLabel":"Order history"},
      props: true,
      name: "customer-single-order___niche_nb_NO"
    }]
  }, {
    path: "/faq.html",
    component: _2fb8e076,
    name: "faq___niche_nb_NO"
  }, {
    path: "/netscheckout",
    component: _6a1b6320,
    name: "netsCheckout___niche_nb_NO"
  }, {
    path: "/nettbutikk",
    component: _195ed51c,
    name: "nettbutikk___niche_nb_NO"
  }, {
    path: "/onskeliste",
    component: _2abcbd37,
    name: "wishlist___niche_nb_NO"
  }, {
    path: "/search",
    component: _65f29b97,
    name: "search___niche_nb_NO"
  }, {
    path: "/blog/Blog",
    component: _49b1320c,
    name: "blog-Blog___niche_nb_NO"
  }, {
    path: "/blog/BlogPost",
    component: _218e04cc,
    name: "blog-BlogPost___niche_nb_NO"
  }, {
    path: "/catalog/Category",
    component: _26f75332,
    name: "catalog-Category___niche_nb_NO"
  }, {
    path: "/customer/reset-password",
    component: _b804425a,
    alias: "/customer/account/createPassword",
    name: "reset-password___niche_nb_NO"
  }, {
    path: "/theme/Cms",
    component: _23f88440,
    name: "theme-Cms___niche_nb_NO"
  }, {
    path: "/theme/Home",
    component: _3c4f9958,
    name: "theme-Home___niche_nb_NO"
  }, {
    path: "/customer/MyAccount/MyAccount",
    component: _409b5dd5,
    name: "customer-MyAccount-MyAccount___niche_nb_NO"
  }, {
    path: "/blogg-restaurantinnredning/:slug+",
    component: _218e04cc,
    name: "blogPost___niche_nb_NO"
  }, {
    path: "/referanser/:slug",
    component: _ed424286,
    name: "projectDetail___niche_nb_NO"
  }, {
    path: "/collection/:slug([^/.]+).html",
    component: _75254702,
    name: "attributeCollection___niche_nb_NO"
  }, {
    path: "/collection/:collection_slug/:slug+.html",
    component: _9919c096,
    name: "attributeCollectionItem___niche_nb_NO"
  }, {
    path: "/",
    component: _3c4f9958,
    name: "home___niche_nb_NO"
  }, {
    path: "/:slug+",
    component: _7fea061d,
    name: "page___niche_nb_NO"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
